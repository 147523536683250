import { graphql, PageProps } from 'gatsby';
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews';
import * as React from 'react';

import { BmButton } from '../components/button';
import { Footer } from '../components/footer';
import { Hero } from '../components/hero';
import { RichText } from '../components/richText';
import { View } from '../components/view';
import { FourOhFourQuery } from '../generated/graphql';
import { Routes } from '../utils/routes';

import './404.scss';

export const FourOhFourView: React.FC<PageProps<FourOhFourQuery>> = ({ data }) => {
  return (
    <View title="404" className="four-oh-four-view">
      <Hero backgroundVideoUrl={data.prismic404?.data.video_url || undefined} shape="square">
        <RichText field={data.prismic404?.data.copy?.richText} />
        <BmButton to={Routes.home}>{data.prismic404?.data.button_text}</BmButton>
      </Hero>

      <Footer />
    </View>
  );
};

export default withPrismicUnpublishedPreview(FourOhFourView);

export const pageQuery = graphql`
  query FourOhFour {
    prismic404 {
      _previewable
      data {
        copy {
          richText
        }
        button_text
        video_url
      }
    }
  }
`;
